import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import './meetings.css';

const Meetings = () => {
  const { community_code } = useParams(); 
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newMeeting, setNewMeeting] = useState({
    title: '',
    description: '',
    start_time: '',
    end_time: '',
  });

  // Fetch meetings
  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        console.log(community_code);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/meetings/${community_code}/meetings/`);
        setMeetings(response.data);
      } catch (err) {
        setError('Error fetching meetings');
      } finally {
        setLoading(false);
      }
    };

    fetchMeetings();
  }, [community_code]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMeeting({ ...newMeeting, [name]: value });
  };

  const convertToUTC = (localDate) => {
    const date = new Date(localDate);  // Create Date object from the local date
    return date.toISOString();         // Convert to UTC and format as ISO 8601 string
  };

  // Create new meeting
  const handleCreateMeeting = async (e) => {
    e.preventDefault();
    try {
        const start_time_utc = convertToUTC(newMeeting.start_time);
        const end_time_utc = convertToUTC(newMeeting.end_time);
        const meetingData = {
            ...newMeeting,
            start_time: start_time_utc,
            end_time: end_time_utc,
            capacity: 10,
            duration_minutes: 60,
            created_by: 'Grahem',
          };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/meetings/${community_code}/meetings/`, meetingData);
      setMeetings([...meetings, response.data]); // Add new meeting to the list
      alert('Meeting created successfully!');
    } catch (err) {
      setError('Error creating the meeting');
    }
  };

  return (
    <div className="upcoming-meetings-container">
      <h1>Upcoming Meetings</h1>
      
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul className="meetings-list">
          {meetings.map((meeting) => (
            <li key={meeting.id} className="meeting-item">
              <Link to={`/meetings/${community_code}/meeting/${meeting.meeting_code}`} >{meeting.title}</Link>
              <p>{meeting.description}</p>
              <p><strong>Start Time:</strong> {new Date(meeting.start_time).toLocaleString()}</p>
              <p><strong>End Time:</strong> {new Date(meeting.end_time).toLocaleString()}</p>
            </li>
          ))}
        </ul>
      )}

      <div className="create-meeting-form">
        <h2>Create a New On-Demand Meeting</h2>
        <form onSubmit={handleCreateMeeting}>
          <input
            type="text"
            name="title"
            placeholder="Meeting Title"
            value={newMeeting.title}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="description"
            placeholder="Meeting Description"
            value={newMeeting.description}
            onChange={handleInputChange}
            required
          />
          <input
            type="datetime-local"
            name="start_time"
            value={newMeeting.start_time}
            onChange={handleInputChange}
            required
          />
          <input
            type="datetime-local"
            name="end_time"
            value={newMeeting.end_time}
            onChange={handleInputChange}
            required
          />
          <button type="submit">Create Meeting</button>
        </form>
      </div>
    </div>
  );
};

export default Meetings;
