import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { ProfileContext } from '../context/profile-context';
import logo from '../assets/youdoyou_logo_transparent.png';
import CommentModal from './comment-modal';
import defaultProfileImage from '../assets/youdoyou-logo.png';
import './nav-bar.css';

const NavBar = () => {
    const { profileImage, setProfileImage } = useContext(ProfileContext);
    const [showModal, setShowModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [displayName, setDisplayName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    // Fetch profile image when displayName changes
    useEffect(() => {
        const storedDisplayName = localStorage.getItem('displayName');
        const token = localStorage.getItem('authToken');
        

        setDisplayName(storedDisplayName);
        setIsLoggedIn(!!token);

        if (profileImage) {
            setProfileImage(profileImage);
        }
        else if (storedDisplayName) {
            fetchProfileImage(storedDisplayName);
        }
    }, []);

    const fetchProfileImage = async (displayName) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${displayName}/profile-pic/`);

            if (response.ok) {
                const blob = await response.blob();
                const imageUrl = URL.createObjectURL(blob);
                setProfileImage(imageUrl); // Update context state
            } else if (response.status === 404) {
                setProfileImage(defaultProfileImage);
            }
        } catch (error) {
            console.error('Error fetching profile image:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Sign out function: includes clearing local storage and Google sign-out
    const handleSignOut = () => {
        googleLogout();
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('profileImage');
        localStorage.removeItem('displayName');
        setProfileImage(defaultProfileImage);
        setIsLoggedIn(false);
        navigate('/');
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <nav className="bg-gray-800 p-4" id="navbar">
                <div className="container mx-auto flex justify-between items-center">
                    <div className="text-white text-lg">
                        <Link to='/landing'>
                            <img src={logo} alt="Logo" className="h-8" />
                        </Link>
                    </div>
                    <div className="text-white flex items-center">
                        <div className="mr-4">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-xl"
                                onClick={openModal}>
                                feedback
                            </button>
                        </div>
                        {isLoggedIn && location.pathname != '/' && (
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded-xl"
                                onClick={handleSignOut}
                            >
                                Logout
                            </button>
                        )}
                        {isLoggedIn && location.pathname != '/' && (
                            <Link to="/profile">
                                {profileImage && (
                                    <img
                                        src={profileImage || defaultProfileImage}
                                        alt="Profile"
                                        className="profile-picture"
                                    />
                                )}
                            </Link>
                        )}
                    </div>
                </div>
            </nav>

            {showModal && <CommentModal showModal={showModal} onClose={closeModal} />}
        </>
    );
};

export default NavBar;
