import React, { createContext, useState } from 'react';

export const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
    const [profileImage, setProfileImage] = useState(null);

    return (
        <ProfileContext.Provider value={{ profileImage, setProfileImage }}>
            {children}
        </ProfileContext.Provider>
    );
};

export default ProfileProvider;