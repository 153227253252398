import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./meeting.css";

const Meeting = () => {
    const { meeting_code } = useParams();
    const { community_code } = useParams();
    const [meetingDetails, setMeetingDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch meeting details
    useEffect(() => {
        const fetchMeetingDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/meetings/${community_code}/meetings/${meeting_code}/`
                );
                setMeetingDetails(response.data);
                console.log(response.data);
                setIsLoading(false);
            } catch (err) {
                setError("Failed to load meeting details. Please try again.");
                setIsLoading(false);
            }
        };

        fetchMeetingDetails();
    }, [meeting_code]);

    // Join the Zoom meeting
    const joinMeeting = async () => {
        try {

            const sessionResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/meetings/${community_code}/meetings/${meeting_code}/session/`,
                { meetingNumber: meetingDetails.meetingNumber, role: 0 }
            );
            console.log(sessionResponse);
            const session_id = sessionResponse.data.session_id;

            const signatureResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/meetings/${community_code}/meetings/${meeting_code}/${session_id}/atendees/`,
                { meetingNumber: meetingDetails.meetingNumber, role: 0 }
            );
            const signature = signatureResponse.data.signature;

            

            // Step 3: Configure meeting details and join
            
        } catch (err) {
            console.error("Error generating signature or joining meeting:", err);
            setError("Error joining the meeting.");
        }
    };

    if (isLoading) {
        return <div>Loading meeting details...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div className="meeting">
            <h1>{meetingDetails.title}</h1>
            <p>{meetingDetails.description}</p>
            <p>
                <strong>Scheduled at:</strong>{" "}
                {new Date(meetingDetails.scheduled_at).toLocaleString()}
            </p>
            <p>
                <strong>Capacity:</strong> {meetingDetails.capacity || "Unlimited"}
            </p>
            <button onClick={joinMeeting} className="join-button">
                Join Meeting
            </button>
        </div>
    );
};

export default Meeting;
