import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './profile.css';
import { ProfileContext } from '../context/profile-context';
import { ContainerWithChildren } from 'postcss/lib/container';

const ProfilePage = () => {
  const [profile, setProfile] = useState({
    displayName: "",
    profile_image: "", // Will store the URL of the image
    struggles: "",
    interests: "",
    zip_code: "",
    age: "", // Use an empty string instead of null
    gender: "",
    school: "",
    education_level: "",
    work: "",
    occupation: "",
    language: "",
    second_language: "",
    religion: "",
    bio: "",
    location: "",
    hobbies: [],
    emotional_state: "",
    takes_medication: false,
    is_in_need_of_support: false,
    is_mentor: false,
    been_hospitalized: false,
    diagnoses: [],
    avatar: "",
    privacy_setting: "Public",
    website: "", // Default to an empty string
    faceBook: "",
    instagram: "",
    tiktok: "",
    snapChat: "",
    youtube: "",
    x: "",
  });


  const { profileImage, setProfileImage } = useContext(ProfileContext);
  const [selectedImage, setSelectedImage] = useState(null); // Stores the file for upload
  const [imagePreview, setImagePreview] = useState(""); // Stores the image preview URL
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const defaultImage = "/path-to-default-image.png"; // Default image when no profile image is available

  // Load profile and profile image from localStorage
  useEffect(() => {
    const displayName = localStorage.getItem("displayName");

    if (displayName) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        displayName: displayName,
      }));
    } else {
      setError("Display name not found");
      setLoading(false);
    }
  }, []);

  // Fetch the user profile if not already loaded
  useEffect(() => {
    if (profile.displayName) {
      const fetchUserProfile = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/users/${profile.displayName}/user-profile/`
          );

          setProfile((prevProfile) => ({
            ...prevProfile,
            ...response.data,
          }));

          if (profileImage) {

            console.log('Profile Image:', profileImage);
            setImagePreview(profileImage);
          } else {
            try {
              console.time("fetch profile image");
              const imageResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/users/${profile.displayName}/profile-pic/`,
                { responseType: "blob" }
              );
              console.timeEnd("fetch profile image");
              const imageUrl = URL.createObjectURL(imageResponse.data);
              setImagePreview(imageUrl);
              setProfileImage(imageUrl);
            } catch (error) {
              console.error("Error fetching profile image", error);
              setProfileImage(defaultImage);
            }
          }

          setLoading(false);
        } catch (error) {
          console.error("Error fetching user profile", error);
          setError("Failed to load profile");
          setLoading(false);
        }
      };

      fetchUserProfile();
    }
  }, [profile.displayName]);

  // Handle file input change for profile image
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const fileUrl = URL.createObjectURL(file);
      setImagePreview(fileUrl);
      setProfileImage(fileUrl);
    }
  };

  // Handle input change for other profile fields
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProfile({
      ...profile,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleChangeImageClick = () => {
    document.getElementById('profileImageInput').click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const cleanProfile = { ...profile };

    Object.keys(cleanProfile).forEach((key) => {
      const value = cleanProfile[key];
      formData.append(key, value === "" ? null : value);
    });

    if (selectedImage) {
      formData.append("profile_image", selectedImage);
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/${profile.displayName}/user-profile/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (selectedImage) {
        const newProfileImageUrl = URL.createObjectURL(selectedImage);
        localStorage.setItem("profileImage", newProfileImageUrl);
      }
    } catch {
      setError("Error updating profile");
    }
  };


  // Handle loading and error states
  if (loading) return <div className="message"><p>Loading profile...</p></div>;
  if (error) return <div className="message"><p>{error}</p></div>;

  return (
    <div className="profile-container">
      <h2 className="profile-heading">User Profile</h2>
      <form className="profile-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group image-upload">
          <label>Profile Image</label>
          <div className="profile-image-preview">
            {imagePreview ? (
              <img src={imagePreview} alt="Profile Preview" className="profile-image" />
            ) : (
              <span>No profile image</span>
            )}
          </div>
          <button type="button" className="change-image-btn" onClick={handleChangeImageClick}>
            Change
          </button>
          <input
            type="file"
            id="profileImageInput"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleProfileImageChange}
          />
        </div>

        {/* Basic Info */}
        <div className="profile-group-inline">
          <div>
            <label>Display Name</label>
            <input type="text" name="displayName" value={profile.displayName || ""} onChange={handleInputChange} />
          </div>
          <div>
            <label>Age</label>
            <input type="number" name="age" value={profile.age || ""} onChange={handleInputChange} />
          </div>
          <div>
            <label>Gender</label>
            <input type="text" name="gender" value={profile.gender || ""} onChange={handleInputChange} />
          </div>
          <div>
            <label>Zip Code</label>
            <input type="text" name="zip_code" value={profile.zip_code || ""} onChange={handleInputChange} />
          </div>
        </div>

        {/* Struggles and Interests */}
        <div className="profile-group">
          <label>Struggles</label>
          <textarea name="struggles" value={profile.struggles || ""} onChange={handleInputChange} />
        </div>

        <div className="profile-group">
          <label>Interests</label>
          <textarea name="interests" value={profile.interests || ""} onChange={handleInputChange} />
        </div>

        {/* Education and Work */}
        <div className="profile-group-inline">
          <div>
            <label>School</label>
            <input type="text" name="school" value={profile.school || ""} onChange={handleInputChange} />
          </div>
          <div>
            <label>Education Level</label>
            <input type="text" name="education_level" value={profile.education_level || ""} onChange={handleInputChange} />
          </div>
          <div>
            <label>Occupation</label>
            <input type="text" name="occupation" value={profile.occupation || ""} onChange={handleInputChange} />
          </div>
        </div>

        {/* Additional Info */}
        <div className="profile-group-inline">
          <div>
            <label>Work</label>
            <input type="text" name="work" value={profile.work || ""} onChange={handleInputChange} />
          </div>
          <div>
            <label>Location</label>
            <input type="text" name="location" value={profile.location || ""} onChange={handleInputChange} />
          </div>
          <div>
            <label>Hobbies</label>
            <input type="text" name="hobbies" value={profile.hobbies || ""} onChange={handleInputChange} />
          </div>
        </div>

        {/* Social Media Links */}
        <div className="profile-group">
          <label>Social Media</label>
          <input type="url" name="website" value={profile.website || ""} onChange={handleInputChange} placeholder="Website" />
          <input type="url" name="faceBook" value={profile.faceBook || ""} onChange={handleInputChange} placeholder="Facebook" />
          <input type="url" name="instagram" value={profile.instagram || ""} onChange={handleInputChange} placeholder="Instagram" />
          <input type="url" name="tiktok" value={profile.tiktok || ""} onChange={handleInputChange} placeholder="TikTok" />
          <input type="url" name="snapChat" value={profile.snapChat || ""} onChange={handleInputChange} placeholder="Snapchat" />
          <input type="url" name="youtube" value={profile.youtube || ""} onChange={handleInputChange} placeholder="YouTube" />
          <input type="url" name="x" value={profile.x || ""} onChange={handleInputChange} placeholder="X (Twitter)" />
        </div>

        {/* Privacy and Preferences */}
        <div className="profile-group-inline">
          <div>
            <label>Privacy Setting</label>
            <select name="privacy_setting" value={profile.privacy_setting || ""} onChange={handleInputChange}>
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </select>
          </div>
          <div>
            <label>Takes Medication</label>
            <input type="checkbox" name="takes_medication" checked={profile.takes_medication || false} onChange={handleInputChange} />
          </div>
          <div>
            <label>Needs Support</label>
            <input type="checkbox" name="is_in_need_of_support" checked={profile.is_in_need_of_support || false} onChange={handleInputChange} />
          </div>
          <div>
            <label>Mentor</label>
            <input type="checkbox" name="is_mentor" checked={profile.is_mentor || false} onChange={handleInputChange} />
          </div>
        </div>

        {/* Save Button */}
        <button type="submit" className="save-btn">Save Profile</button>
      </form>
    </div>
  );
};

export default ProfilePage;
